import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { useAuth } from "@auth/useAuth"
import get from "lodash/get"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { format, parse } from "date-fns"
import axios from "axios"

const AdminBookings = () => {
  const { makeRequest } = useAuth() || {}
  const [meetingRoomBookings, setMrb] = useState([])
  const [progress, setProgress] = useState(false)

  const [activeMr, setAi] = useState(null)

  const fetchBookings = () => {
    makeRequest("meeting-rooms/list")
      .then((res) => {
        setMrb(get(res, "bookings", []))
      })
      .catch(() => null)
  }

  useEffect(() => {
    fetchBookings()
  }, [])

  const cancelReservation = async (reservationId = null) => {
    setProgress(true)
    const booking = await makeRequest("meeting-rooms/cancel-booking", {
      reservationId,
    })
    await fetchBookings()
    setProgress(false)
  }

  const declineBooking = async () => {
    setProgress(true)
    const res = window.confirm(
      `Decline the booking for ${activeMr?.meeting_room_name}?`
    )
    if (!res) {
      setProgress(false)
      setAi(false)
      return
    }
    const booking = await axios
      .post(
        `${process.env.GATSBY_MVP_API_URL}/worksimply-meeting-room-bookings/cancel-booking-request`,
        {
          id: activeMr?.id,
        }
      )
      .then((res) => res?.data)
      .catch((e) => {
        console.log(e)
        return {}
      })

    const fromD = get(activeMr, "from", "").split("T")[0]
    const toD = get(activeMr, "to", "").split("T")[0]

    const fromDate = parse(
      fromD + " " + activeMr.arrival_time,
      "yyyy-MM-dd HH:mm:ss",
      new Date()
    )
    const toDate = parse(
      toD + " " + activeMr.departure_time,
      "yyyy-MM-dd HH:mm:ss",
      new Date()
    )
    await makeRequest("handle-form", {
      to: booking.user_email,
      userEmail: booking?.user_email,
      sp_slug: "worksimply",
      replyTo: "hello@worksimply.com",
      emailTitle: `Your request to book ${booking?.meeting_room_name} was declined`,
      emailData: [
        {
          label: "Meeting Room Name",
          value: booking?.meeting_room_name,
        },
        {
          label: "Location",
          value: booking?.location_name,
        },
        {
          label: "From",
          value: `${format(fromDate, "yyyy-MM-dd h:mm a")}`,
        },
        {
          label: "To",
          value: `${format(toDate, "yyyy-MM-dd h:mm a")}`,
        },
        {
          label: "Note",
          value: "This meeting rooms is not available for the requested date",
        },
      ],
    })
    await fetchBookings()
    setProgress(false)
    setAi(false)
  }

  const confirmBooking = async () => {
    setProgress(true)
    const res = window.confirm(
      `Confirm the booking for ${activeMr?.meeting_room_name}?`
    )
    if (!res) {
      setProgress(false)
      setAi(false)
      return
    }
    await axios
      .post(
        `${process.env.GATSBY_MVP_API_URL}/worksimply-meeting-room-bookings/confirm-booking-request`,
        {
          id: activeMr?.id,
        }
      )
      .then((res) => res?.data)
      .catch((e) => {
        console.log(e)
        return {}
      })

    await fetchBookings()
    setProgress(false)
    setAi(false)
  }

  const noBookings = !meetingRoomBookings || meetingRoomBookings.length === 0
  return (
    <>
      <SEO title="Meeting Room Bookings" />
      <Layout title={"Meeting Room Bookings"} progress={noBookings}>
        {noBookings ? (
          <p>Loading...</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>SO</th>
                <th>Date</th>
                <th>Service Fee</th>
                <th>Total</th>
                <th>Status</th>
                <th>Type</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {meetingRoomBookings.map((b) => {
                const date = get(b, "from", "").split("T")[0]
                return (
                  <tr key={b.id}>
                    <td>{get(b, "spaceOperator.name", "N/A")}</td>
                    <td>{date}</td>
                    <td>${b.service_fee / 100}</td>
                    <td>${b.total / 100}</td>
                    <td>{get(b, "status.label", "N/A")}</td>
                    <td>{b?.type}</td>
                    <td>
                      <button
                        className={"btn btn-sm btn-light px-0 "}
                        onClick={() => setAi(b)}
                      >
                        Details
                      </button>
                    </td>
                    <td>
                      {!b.payment_completed ? (
                        <>
                          {b.reservation_status_id === 3 ? (
                            <p className="m-0 text-muted">Cancelled</p>
                          ) : (
                            <button
                              className={
                                "clear-button-styles  text-danger px-0 "
                              }
                              onClick={() => cancelReservation(b.id)}
                              disabled={progress}
                            >
                              Cancel
                            </button>
                          )}
                        </>
                      ) : (
                        <p className="m-0 text-success">Payment completed</p>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        <Modal isOpen={!!activeMr} toggle={() => setAi(false)} centered>
          <ModalHeader toggle={() => setAi(false)}>
            Reservation Data
          </ModalHeader>
          <ModalBody>
            <table className="table table-bordered m-0 table-striped table-hover">
              <tbody>
                <tr>
                  <td>Reservation ID</td>
                  <td>{activeMr?.id}</td>
                </tr>
                <tr>
                  <td>Location Name</td>
                  <td>{activeMr?.location_name || "N/A"}</td>
                </tr>
                <tr>
                  <td>Meeting Room Name</td>
                  <td>{activeMr?.meeting_room_name || "N/A"}</td>
                </tr>
                <tr>
                  <td>User Name</td>
                  <td>{activeMr?.user_name}</td>
                </tr>
                <tr>
                  <td>User Email</td>
                  <td>{activeMr?.user_email}</td>
                </tr>
                {activeMr?.type === "request_to_book" &&
                activeMr?.reservation_status_id === 1 ? (
                  <>
                    <tr>
                      <td>User's Company</td>
                      <td>
                        {activeMr?.reservation_data?.userCompanyName || "N/A"}
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>Reservation ID</td>
                      <td>{activeMr?.reservation_data?.reference || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Stripe Customer</td>
                      <td>
                        {activeMr?.stripe_connected_account_id &&
                        activeMr?.stripe_customer_id ? (
                          <a
                            href={`https://dashboard.stripe.com/${activeMr?.stripe_connected_account_id}/customers/${activeMr?.stripe_customer_id}`}
                            target={"_blank"}
                            className={"text-dark"}
                            rel={"noreferrer"}
                          >
                            {activeMr?.stripe_customer_id || "N/A"}
                          </a>
                        ) : (
                          activeMr?.stripe_customer_id || "N/A"
                        )}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>Price</td>
                  <td>${activeMr?.price / 100}</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td>${activeMr?.tax / 100}</td>
                </tr>
                <tr>
                  <td>Service Fee</td>
                  <td>${activeMr?.service_fee / 100}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>${activeMr?.total / 100}</td>
                </tr>

                <tr>
                  <td>Source</td>
                  <td>{activeMr?.source}</td>
                </tr>
                <tr>
                  <td>Created at</td>
                  <td>
                    {activeMr?.created_at
                      ? format(
                          new Date(activeMr?.created_at),
                          "yyyy-MM-dd hh:mm a"
                        )
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" size="sm" onClick={() => setAi(false)}>
              Close
            </Button>
            {activeMr?.type === "request_to_book" &&
              activeMr?.reservation_status_id === 1 && (
                <>
                  <Button
                    color="danger"
                    size="sm"
                    className={"ml-auto"}
                    onClick={declineBooking}
                    disabled={progress}
                  >
                    Decline
                  </Button>
                  <Button
                    color="success"
                    size="sm"
                    onClick={confirmBooking}
                    disabled={progress}
                  >
                    Confirm
                  </Button>
                </>
              )}
          </ModalFooter>
        </Modal>
      </Layout>
    </>
  )
}

export default AdminBookings
